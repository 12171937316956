import React from 'react'
import styled from 'styled-components'
import useLastTruthy from 'hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  margin-top: ${({ show }) => (show ? '16px' : 0)};
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  max-width: 340px;
  border-radius: 20px;
  background-color: #0A0E46;

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: transform 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 439px;
  }
`

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter
      show={Boolean(trade)}
      style={{ background: '#9EACD030', borderRadius: '16px', width: "439px" }}
    >
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </AdvancedDetailsFooter>
  )
}
