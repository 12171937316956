import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x2774eF7918F6947560FD7128878Cfee3F34535e6', 18, 'BOMB', 'Bomb'),
  [ChainId.TESTNET]: new Token(ChainId.TESTNET, '0x2774eF7918F6947560FD7128878Cfee3F34535e6', 18, 'BOMB', 'Bomb'),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    6,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    6,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.MAINNET, '0x4200000000000000000000000000000000000006', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA', 6, 'DAI', 'Dai Stablecoin')

const tokens = {
  bnb: {
    symbol: 'ETH',
    projectLink: '',
  },
  wbnb: {
    symbol: 'WETH',
    address: {
      /* 882687 */ 8453: '0x4200000000000000000000000000000000000006',
      882688: '0x4200000000000000000000000000000000000006',
    },
    decimals: 18,
    projectLink: '',
  },
  cake: {
    symbol: 'BOMB',
    address: {
      /* 882687 */ 8453: '0x2774eF7918F6947560FD7128878Cfee3F34535e6',
      882688: '0x2774eF7918F6947560FD7128878Cfee3F34535e6',
    },
    decimals: 18,
    projectLink: '',
  },
  dai: {
    symbol: 'DAI',
    address: {
      /* 882687 */ 8453: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      882688: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    },
    decimals: 6,
    projectLink: '',
  },
}

export default tokens
