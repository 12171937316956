import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 4,
    lpSymbol: 'BOMB-ETH LP',
    isTokenOnly: false,
    lpAddresses: {
      882688: '0x3f5A93E45756f08659Ab31f0dA851bF62E9775b4',
      /* 882687 */ 8453: '0x9aa3170c8fb28cbd332b803b13c866043b05057c',
    },
    token: tokens.cake,
    quoteToken: tokens.wbnb,
    decimals: 18,
  },
  {
    pid: 5,
    lpSymbol: 'USDbC-BOMB LP',
    isTokenOnly: false,
    lpAddresses: {
      882688: '0x3f5A93E45756f08659Ab31f0dA851bF62E9775b4',
      /* 882687 */ 8453: '0x6ace96b0b4bca3bdf378b636544a5937ab3cea4b',
    },
    token: tokens.dai,
    quoteToken: tokens.cake,
    decimals: 18,
  },
  {
    pid: 0,
    lpSymbol: 'USDbC-ETH LP',
    isTokenOnly: false,
    lpAddresses: {
      882688: '0x3f5A93E45756f08659Ab31f0dA851bF62E9775b4',
      /* 882687 */ 8453: '0xffeccbc156705886b901bb0122c6cd5311461a92',
    },
    token: tokens.dai,
    quoteToken: tokens.wbnb,
    decimals: 18,
  },
  {
    pid: 3,
    lpSymbol: 'BOMB',
    isTokenOnly: true,
    lpAddresses: {
      882688: '0x2774eF7918F6947560FD7128878Cfee3F34535e6',
      /* 882687 */ 8453: '0x2774eF7918F6947560FD7128878Cfee3F34535e6',
    },
    token: tokens.cake,
    quoteToken: tokens.cake,
    decimals: 18,
  },
  {
    pid: 1,
    lpSymbol: 'WETH',
    isTokenOnly: true,
    lpAddresses: {
      882688: '0x4200000000000000000000000000000000000006',
      /* 882687 */ 8453: '0x4200000000000000000000000000000000000006',
    },
    token: tokens.wbnb,
    quoteToken: tokens.wbnb,
    decimals: 18,
  },
  {
    pid: 2,
    lpSymbol: 'USDbC',
    isTokenOnly: true,
    lpAddresses: {
      882688: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
      /* 882687 */ 8453: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    },
    token: tokens.dai,
    quoteToken: tokens.dai,
    decimals: 6,
  },  
  // {
  //   pid: 0,
  //   lpSymbol: 'Bomb',
  //   lpAddresses: {
  //     882688: '0x05c86418c11d610d28268e0C2aF2C2386fB38FDd',
  //     /* 882687 */ 8453: '0x2774eF7918F6947560FD7128878Cfee3F34535e6',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'Bomb-ETH LP',
  //   lpAddresses: {
  //     882688: '0x3f5A93E45756f08659Ab31f0dA851bF62E9775b4',
  //     /* 882687 */ 8453: '0x90865a1F9cC2aD4530616f6F62a69bf216f6CF54',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'USDC-BOMB LP',
  //   lpAddresses: {
  //     882688: '0x3f5A93E45756f08659Ab31f0dA851bF62E9775b4',
  //     /* 882687 */ 8453: '0xcddd469470a2260cd7f10248cabf6b3d30b346a8',
  //   },
  //   token: tokens.dai,
  //   quoteToken: tokens.cake,
  // },
  
]

export default farms
