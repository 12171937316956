import React from 'react'
import { Button, useWalletModal } from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'

import styled from "styled-components";

const StyledConnectWalletButton = styled.div`
  background: #2d98fb;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 1.75rem;
  color: white;
  font-size: 13px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const CardWraper = styled.div`
  background-image: url(/images/walletIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  
  height: 28px;
  width: 32px;

  ${({ theme }) => theme.mediaQueries.lg} {
    height: 28px;
    width: 24px;
  }
`

const ConnectWalletButton = () => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t)

  return (
    <StyledConnectWalletButton onClick={onPresentConnectModal} >
      <CardWraper />
      {t('Connect Wallet')}
    </StyledConnectWalletButton>
  )
}

export default ConnectWalletButton
