import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.cake,
    earningToken: tokens.cake,
    contractAddress: {
      882688: '0x6e2c1dbAd941dC69457264ABa9110Ef7D2553679',
      /* 882687 */ 8453: '0xE5A2ec9982bb890F633B5451da229683f5A7b51a',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.5',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools
