import React from 'react'
import styled from 'styled-components'
import { LiquidityCard } from '@pancakeswap/uikit'

export const LiquidityBodyWrapper = styled(LiquidityCard)`
  border-radius: 24px;
  max-width: 439px;
  width: 100%;
  z-index: 1;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function LiquidityBody({ children }: { children: React.ReactNode }) {
  return <LiquidityBodyWrapper>{children}</LiquidityBodyWrapper>
}
